<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <v-chip-group v-model="delivery.type" column active-class="primary">
              <v-chip :value="'delivery'" filter> Entrega </v-chip>
              <v-chip :value="'pickup'" filter> Retira expedição </v-chip>
            </v-chip-group>
          </v-col>
          <v-col cols="5">
            <app-text-field
              v-model="delivery.scheduled_to"
              type="date"
              label="Data Agendada"
            />
          </v-col>
        </v-row>
        <v-row v-if="delivery.type == 'delivery'">
          <v-col>
            <CompanySelect
              v-model="delivery.company"
              :filter="(company) => company.can_process_deliveries == 1"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-card color="grey lighten-3 " elevation="0">
          <v-card-title v-text="customerName" />
          <v-card-subtitle class="d-flex justify-space-between">
            <span v-if="delivery.sale.salesman_id">
              Vendedor: {{ delivery.sale.salesman.name }}
            </span>
            <span> Venda Nº {{ delivery.sale.number }} </span>
            <span>
              {{ $format.dateBr(delivery.sale.saved_at) }}
            </span>
          </v-card-subtitle>
          <v-card-text>
            <template v-if="hasCustomer">
              <h4 class="my-2">Telefones do Cliente</h4>
              <v-row>
                <v-col>
                  <AppShowPhones :phones="delivery.sale.customer.phones" />
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="delivery.type == 'delivery'">
      <h3 class="mt-6">Endereço de Entrega</h3>
      <AddressSelect
        v-if="hasCustomer"
        :options="delivery.sale.customer.address"
        v-model="delivery.main_address"
      />
      <AddressForm v-if="!hasCustomer" v-model="delivery.main_address" />
    </template>

    <h3 class="my-6">Produtos</h3>
    <v-row>
      <v-col class="text-right">
        <v-data-table
          :headers="headers"
          :items="delivery.delivery_products"
          :server-items-length="-1"
          :items-per-page="-1"
          hide-default-footer
          disable-sort
          dense
        >
          <template v-slot:[`item.quantity_selled`]="{ item }">
            {{ $format.decimal(item.quantity_selled) }}
          </template>
          <template v-slot:[`item.company`]="{ item }">
            <CompanyChip :company="item.product_company.company" />
          </template>
          <template v-slot:[`item.quantity_to_delivery`]="{ item }">
            {{ $format.decimal(item.quantity_to_delivery) }}
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <app-number-field v-model="item.quantity" />
          </template>
          <template v-slot:[`item.actions`]="{ index, item }">
            <v-btn @click="deliveryAllProduct(index, item)" icon>
              <v-icon>mdi-package-variant-closed-plus</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-btn
          @click="deliveryAll()"
          class="text-capitalize mt-4"
          text
          color="info"
        >
          Entregar tudo
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-textarea
          v-model="delivery.comment"
          dense
          auto-grow
          label="Obervações"
          outlined
          rows="1"
        />
      </v-col>
    </v-row>

    <!-- <template v-for="(deliveryProduct, index) of delivery.delivery_products">
      <v-row :key="index" class="mt-2">
        <v-col cols="2">
          {{ deliveryProduct.product.name }}
        </v-col>
        <v-col cols="2">
          {{ $format.decimal(deliveryProduct.quantity_selled) }}
        </v-col>
        <v-col cols="2">
          {{ $format.decimal(deliveryProduct.quantity_to_delivery) }}
        </v-col>
        <v-col cols="2">
          <app-number-field
            v-model="deliveryProduct.quantity"
            label="Quantidade"
          />
        </v-col>
      </v-row>
      <v-divider />
    </template> -->
    <v-row class="text-end">
      <v-col>
        <v-btn text class="mr-4" color="secondary" @click="back()">
          Voltar
        </v-btn>
        <v-btn color="primary" @click="storeDelivery()"> Salvar </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import AddressSelect from "@/components/app/sections/AddressSelect.vue";
import AppShowPhones from "@/components/app/sections/AppShowPhones.vue";
import AddressForm from "@/components/app/forms/AddressForm.vue";
import CompanyChip from "@/components/company/sections/CompanyChip.vue";
import DeliveryObject from "@/services/delivery/DeliveryObject.js";

export default {
  components: {
    CompanySelect,
    AddressSelect,
    AppShowPhones,
    AddressForm,
    CompanyChip,
  },
  data: () => ({
    addressType: null,
    headers: [
      { text: "Código", value: "product.code", align: "center", width: "5%" },
      { text: "Empresa", value: "company" },
      { text: "Descrição", value: "product.name" },
      {
        text: "Vendido",
        value: "quantity_selled",
        align: "right",
        width: "15%",
      },
      {
        text: "Disponível",
        value: "quantity_to_delivery",
        align: "right",
        width: "15%",
      },
      { text: "Entregar", value: "quantity", align: "right", width: "15%" },
      { text: "", value: "actions", align: "right", width: "5%" },
    ],

    sale: {},

    delivery: {
      sale: {
        customer: { address: [] },
      },
      company: null,
      comment: null,
      scheduled_to: null,
      type: null,
      delivery_products: [],
      main_address: {
        type: "mainAddress",
      },
    },
  }),

  mounted() {
    this.showSale();
  },

  computed: {
    customerName() {
      return this.hasCustomer
        ? this.delivery.sale.customer.name
        : "Consumidor Final";
    },
    hasCustomer() {
      if (this.delivery.sale) {
        return this.delivery.sale.customer_id != null;
      }
    },
    user() {
      return this.$store.state.auth.userData.user;
    },

    isManager() {
      return (
        this.user.employee.position == "gerente" ||
        this.user.employee.super_admin == 1
      );
    },
  },

  methods: {
    async showSale() {
      this.$loading.start();

      await this.$http
        .show("sale/sale", this.$route.params.id)
        .then((response) => {
          //this.form = response.customer;
          this.setDeliveryData(response.sale);
          this.$loading.finish();
        });
    },

    setDeliveryData(sale) {
      this.delivery.sale = sale;

      this.delivery.delivery_products = sale.sale_products.map(
        (saleProduct) => {
          return {
            sale_id: saleProduct.sale_id,
            sale_product: saleProduct,
            product: saleProduct.product,
            product_variant: saleProduct.product_variant,
            product_company: saleProduct.product_company,
            quantity_selled: saleProduct.quantity,
            quantity_to_delivery: saleProduct.quantity_to_delivery,
            quantity_scheduled: null,
            quantity: 0,
          };
        }
      );
    },

    async storeDelivery() {
      this.$loading.start();
      var delivery = DeliveryObject.parseDelivery(this.delivery);

      await this.$http
        .store("sale/delivery", delivery)
        .then((response) => {
          this.$loading.finish();
          this.back();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    deliveryAllProduct(index, product) {
      (product.quantity = product.quantity_to_delivery),
        this.$set(this.delivery.delivery_products, index, product);
    },

    deliveryAll() {
      this.delivery.delivery_products.forEach((product) => {
        product.quantity = product.quantity_to_delivery;
      });
    },

    back() {
      var history = this.$router.history;
      history.length > 1 ? history.go(-1) : this.$router.push("/entregas");
    },
    setCompany() {
      if (this.delivery.type == "delivery") {
        this.delivery.company = this.user.employee.company;
      }
      if (!this.delivery.type == "delivery") {
        this.delivery.company = null;
      }
    },
  },
};
</script>

<style>
</style>