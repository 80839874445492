<template>
  <div>
    <v-row>
      <v-slide-group
        v-model="addressType"
        @change="setSelectedAddress($event)"
        class="pa-4"
        show-arrows
      >
        <v-slide-item
          v-for="(option, index) in options"
          :key="index"
          :value="option"
          v-slot="{ active, toggle }"
        >
          <v-card
            elevation="0"
            :color="active ? 'primary' : 'grey lighten-2'"
            class="ma-4"
            @click="toggle"
            :dark="active"
            height="100"
            width="250"
          >
            <v-card-text>
              <v-row>
                <v-col class="pb-0" cols="12">
                  {{ option.address }}, {{ option.number }},
                  {{ option.district }},{{ option.complement }} <br />{{
                    option.city
                  }}, {{ option.state }},
                  {{ $format.cep(option.postal_code) }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-slide-item>
        <v-slide-item value="custom" v-slot="{ active, toggle }">
          <v-card
            elevation="0"
            :color="active ? 'primary' : 'grey lighten-2'"
            class="ma-4"
            @click="toggle"
            :dark="active"
            height="100"
            width="100"
          >
            <v-card-text>
              <v-card-text class="text-center">
                <v-icon x-large>mdi-plus</v-icon>
              </v-card-text>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-row>

    <v-row v-if="addressType == 'custom'">
      <v-col> <AddressForm v-model="address" /> </v-col>
    </v-row>
  </div>
</template>

<script>
import AddressForm from "@/components/app/forms/AddressForm.vue";

export default {
  components: {
    AddressForm,
  },

  props: {
    options: {
      type: Array,
    },
    value: {},
  },

  data() {
    return {
      addressType: null,
      address: {},
    };
  },

  watch: {
    value(val) {
      this.setAddress(val);
    },

    address: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },

  created() {
    this.setAddress(this.value);
  },

  methods: {
    setAddress(value) {
      this.address = value;
    },
    setSelectedAddress(selectedAddress) {
      if (selectedAddress != "custom") {
        this.address = {
          address: selectedAddress.address,
          city: selectedAddress.city,
          city_code: selectedAddress.city_code,
          complement: selectedAddress.complement,
          country: selectedAddress.country,
          country_code: selectedAddress.country_code,
          district: selectedAddress.district,
          number: selectedAddress.number,
          postal_code: selectedAddress.postal_code,
          state: selectedAddress.state,
          state_code: selectedAddress.state_code,
          status: selectedAddress.status,
          type: "mainAddress",
        };
      }
    },
  },
};
</script>

<style>
</style>