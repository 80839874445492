
export default {

  parseDelivery(delivery) {
    return {
      comment: delivery.comment,

      main_address: delivery.main_address,

      scheduled_to: delivery.scheduled_to,

      type: delivery.type,

      company: this.getCompany(delivery),

      delivery_products: this.getDeliveryProducts(delivery),

      sale: { id: delivery.sale.id },
    }
  },

  getCompany(delivery) {
    return delivery.company ? { id: delivery.company.id } : null
  },

  getDeliveryProducts(delivery) {

    return delivery.delivery_products.map((deliveryProduct) => {
      return {

        product: {
          id: deliveryProduct.product.id,
        },

        product_company: {
          id: deliveryProduct.product_company.id,
        },

        product_variant: {
          id: deliveryProduct.product_variant.id,
        },

        sale_product: {
          id: deliveryProduct.sale_product.id,

          product: {
            id: deliveryProduct.sale_product.product_id,
          },

          product_company: {
            id: deliveryProduct.sale_product.product_company_id,
          },

          product_variant: {
            id: deliveryProduct.sale_product.product_variant_id,
          },

          sale_id: deliveryProduct.sale_product.sale_id,
        },

        quantity: deliveryProduct.quantity,
        quantity_scheduled: deliveryProduct.quantity_scheduled,
        quantity_selled: deliveryProduct.quantity_selled,
        quantity_to_delivery: deliveryProduct.quantity_to_delivery,
        sale_id: deliveryProduct.sale_id,
      };
    })
  }
}